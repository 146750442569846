import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../mobile.service';


@Component({
  selector: 'app-new-pwa',
  templateUrl: './new-pwa.component.html',
  styleUrls: ['./new-pwa.component.css']
})
export class NewPwaComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showhidefooter(true);
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showheading("Choose Your Industry");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
    this.mobileservice.showhidemainlogo(true);
    this.mobileservice.showhidelogowhite(false);
    
  }

}
