import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-bigcommerce',
  templateUrl: './bigcommerce.component.html',
  styleUrls: ['./bigcommerce.component.css','./../html/html.component.css']
})
export class BigcommerceComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("BigCommerce");
    this.mobileservice.showlogo(true);
  }

}
