import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-art',
  templateUrl: './art.component.html',
  styleUrls: ['./art.component.css','./../new-startups/startups.component.css']
})
export class ArtComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

     /*... swiper .....*/
      config: SwiperOptions = { 
      pagination: '.swiper-pagination',
      paginationClickable: true,
      spaceBetween: 30
      }
    /*... end swiper .....*/

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("Art, Music & Video");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  
  /* ..... slide effect .... */  
  setTimeout(()=>{
      this.htmlSlideEffect();
      },100);
/* ..... end ngoninit .... */      
} 
  
  htmlSlideEffect()
    {
      document.getElementById("htmltopSliding").style.left="0";
    }
/* ..... end slide effect .... */  
}
