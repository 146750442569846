import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../mobile.service';

@Component({
  selector: 'app-native-to-pwa',
  templateUrl: './native-to-pwa.component.html',
  styleUrls: ['./native-to-pwa.component.css']
})
export class NativeToPwaComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {

    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true); 
    this.mobileservice.showheading("Native to PWA");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");

    setTimeout(()=>
    {
      this.htmlSlideEffect();
    },100);

    setTimeout(()=>
    {
      this.htmlSlideEffect2();
    },500);

    setTimeout(()=>
    {
      this.htmlSlideEffect3();
    },900);
  }
  


  htmlSlideEffect()
		{
			document.getElementById("htmltopSliding1").style.left="0";
    }
    htmlSlideEffect2()
		{
      document.getElementById("htmltopSliding2").style.left="0";
    }
    htmlSlideEffect3()
		{
      document.getElementById("htmltopSliding3").style.left="0";
    }

}
