import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-web',
  templateUrl: './contact-web.component.html',
  styleUrls: ['./contact-web.component.css']
})
export class ContactWebComponent implements OnInit {

  constructor() { }

  ngOnInit() {
   

  }
 private _year = new Date().getFullYear();
  public get year() {
    return this._year;
  }
  public set year(value) {
    this._year = value;
  }

}
