import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../mobile.service';

@Component({
  selector: 'app-chat-bots',
  templateUrl: './chat-bots.component.html',
  styleUrls: ['./chat-bots.component.css', './../video-surveillance/video-surveillance.component.css']
})
export class ChatBotsComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }
  
  /*... swiper .....*/
config: SwiperOptions = { 
  pagination: '.swiper-pagination',
  paginationClickable: true,
  spaceBetween: 30
  }
/*... end swiper .....*/


  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("Chat Bots");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
    this.mobileservice.showhidelogowhite(false);
    this.mobileservice.showhidemainlogo(true);
  }

}
