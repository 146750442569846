import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../mobile.service';


@Component({
  selector: 'app-upgrade-to-pwa',
  templateUrl: './upgrade-to-pwa.component.html',
  styleUrls: ['./upgrade-to-pwa.component.css']
})
export class UpgradeToPwaComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true); 
    this.mobileservice.showheading("Minimum Requirements");
    this.mobileservice.showlogo(false);
  }

}
