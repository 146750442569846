import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-ios-native',
  templateUrl: './ios-native.component.html',
  styleUrls: ['./ios-native.component.css']
})
export class IosNativeComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }


    ngOnInit() {
      this.mobileservice.showhideBackButton(true);
      this.mobileservice.showhidefooter(true);
      this.mobileservice.showheading("iOs to PWA");
      this.mobileservice.showlogo(false);
      this.mobileservice.adclass("head-margin");
    }


}
