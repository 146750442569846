import { Component, OnInit } from '@angular/core';
import { MobileService } from '../mobile.service';

@Component({
  selector: 'app-pure-pwa',
  templateUrl: './pure-pwa.component.html',
  styleUrls: ['./pure-pwa.component.css']
})
export class PurePwaComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {

    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true);
    this.mobileservice.showheading("Progressive Web App");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
    this.mobileservice.showhidemainlogo(true);
    this.mobileservice.showhidelogowhite(false);

    setTimeout(()=>
    {
      this.htmlSlideEffect();
    },100);

    setTimeout(()=>
    {
      this.htmlSlideEffect2();
    },500);

    setTimeout(()=>
    {
      this.htmlSlideEffect3();
    },900);
  }
  


  htmlSlideEffect()
		{
			document.getElementById("htmltopSliding1").style.left="0";
    }
    htmlSlideEffect2()
		{
      document.getElementById("htmltopSliding2").style.left="0";
    }
    htmlSlideEffect3()
		{
      document.getElementById("htmltopSliding3").style.left="0";
    }
  }


