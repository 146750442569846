import { Component, OnInit } from '@angular/core';
import { MobileService } from '../mobile.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  hidefoo: boolean;



  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showfooter.subscribe(hidefooter=>{
      this.hidefoo=hidefooter;
      // console.log('main',this.hidefoo);
    })

    
  }

}
