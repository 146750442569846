import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { WorkComponent } from './work/work.component';
import { PurePwaComponent } from './pure-pwa/pure-pwa.component';
import { NewPwaComponent } from './pure-pwa/new-pwa/new-pwa.component';
import { NativeToPwaComponent } from './pure-pwa/native-to-pwa/native-to-pwa.component';
import { UpgradeToPwaComponent } from './pure-pwa/upgrade-to-pwa/upgrade-to-pwa.component';
import { StartupsComponent } from './pure-pwa/new-pwa/new-startups/startups.component';
import { SocialNetworkComponent } from './pure-pwa/new-pwa/new-social-network/social-network.component';
import { EducationComponent } from './pure-pwa/new-pwa/new-education/education.component';
import { HospitalityComponent } from './pure-pwa/new-pwa/new-hotel/hospitality.component';
import { DonationFundingComponent } from './pure-pwa/new-pwa/donation-funding/donation-funding.component';
import { MedicalHealthcareComponent } from './pure-pwa/new-pwa/new-healthcare/medical-healthcare.component';
import { SportsComponent } from './pure-pwa/new-pwa/sports/sports.component';
import { ArtComponent } from './pure-pwa/new-pwa/new-art/art.component';
import { BusinessComponent } from './pure-pwa/new-pwa/new-business/business.component';
import { FashionComponent } from './pure-pwa/new-pwa/new-fashion/fashion.component';
import { ECommerceComponent } from './pure-pwa/new-pwa/new-e-commerce/e-commerce.component';
import {FoodComponent} from './pure-pwa/new-pwa/food/food.component';
import { HtmlComponent } from './pure-pwa/upgrade-to-pwa/html/html.component';
import { WordPressComponent } from './pure-pwa/upgrade-to-pwa/word-press/word-press.component';
import { PrestashopComponent } from './pure-pwa/upgrade-to-pwa/prestashop/prestashop.component';
import { WooCommerceComponent } from './pure-pwa/upgrade-to-pwa/woo-commerce/woo-commerce.component';
import { ShopifyComponent } from './pure-pwa/upgrade-to-pwa/shopify/shopify.component';
import { JoomlaComponent } from './pure-pwa/upgrade-to-pwa/joomla/joomla.component';
import { PhpComponent } from './pure-pwa/upgrade-to-pwa/php/php.component';
import { OscommerceComponent } from './pure-pwa/upgrade-to-pwa/oscommerce/oscommerce.component';
import { OpencartComponent } from './pure-pwa/upgrade-to-pwa/opencart/opencart.component';
import { MagentoComponent } from './pure-pwa/upgrade-to-pwa/magento/magento.component';
import { DrupalComponent } from './pure-pwa/upgrade-to-pwa/drupal/drupal.component';
import { BigcommerceComponent } from './pure-pwa/upgrade-to-pwa/bigcommerce/bigcommerce.component';
import { BuildwizzComponent } from './work/buildwizz/buildwizz.component';
import { IotComponent } from './iot/iot.component';
import { MiddleNewPwaComponent } from './pure-pwa/middle-new-pwa/middle-new-pwa.component';
import { GoodactComponent } from './work/goodact/goodact.component';
import { AndroidNativeComponent } from './pure-pwa/native-to-pwa/android-native/android-native.component';
import { IosNativeComponent } from './pure-pwa/native-to-pwa/ios-native/ios-native.component';
import { HybridAppComponent } from './pure-pwa/native-to-pwa/hybrid-app/hybrid-app.component';
import { MuntooComponent } from './work/muntoo/muntoo.component';
import { VellnetComponent } from './work/vellnet/vellnet.component';
import { LeaderFirstComponent } from './work/leader-first/leader-first.component';
import { PwaDevelopmentComponent } from './pure-pwa/middle-new-pwa/pwa-development/pwa-development.component';
import { TimeCostComponent } from './pure-pwa/middle-new-pwa/new-time-cost/time-cost.component';
import { ProcessComponent } from './pure-pwa/middle-new-pwa/process/process.component';
import { TechnologyComponent } from './pure-pwa/middle-new-pwa/technology/technology.component';
import { MinimumRequirementsComponent } from './pure-pwa/middle-new-pwa/minimum-requirements/minimum-requirements.component';
import { UpgradeDevelopmentComponent } from './pure-pwa/middle-new-pwa/upgrade-development/upgrade-development.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { SmarthousesComponent } from './iot/smarthouses/smarthouses.component';
import { SmartRetailComponent } from './iot/smart-retail/smart-retail.component';
// import { SmartCitiesComponent } from './iot/smart-cities/smart-cities.component';
import { IotInHealthcareComponent } from './iot/iot-in-healthcare/iot-in-healthcare.component';
// import { SmartGridComponent } from './iot/smart-grid/smart-grid.component';
import { IndustrialIotComponent } from './iot/industrial-iot/industrial-iot.component';
import { VideoSurveillanceComponent } from './ai/video-surveillance/video-surveillance.component';
import { EcommerceComponent } from './ai/ecommerce/ecommerce.component';
import { SocialDigitalMarketingComponent } from './ai/social-digital-marketing/social-digital-marketing.component';
import { ChatBotsComponent } from './ai/chat-bots/chat-bots.component';
import { FreeAuditComponent } from './pure-pwa/middle-new-pwa/free-audit/free-audit.component';
import { WhyUpgradeComponent } from './pure-pwa/middle-new-pwa/upgrade-development/why-upgrade/why-upgrade.component';
import { MillenniumPathLabComponent } from './work/millennium-path-lab/millennium-path-lab.component';
import { from } from 'rxjs';
import { AiComponent } from './ai/ai.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AboutUsComponent } from './about-us/about-us.component';

export const routes=RouterModule.forRoot([
  {path:'',component: HomeComponent ,data:{isHead:true, homelogo:true}},
  {path:'home',component: HomeComponent ,data:{isHead:true , homelogo:true}},
  {path:'about-us', component:AboutUsComponent,data:{isHead:false,homelogo:false}},
  {path:'clients',component: ClientsComponent ,data:{isHead:false,homelogo:false}},
  {path:'contact-us',component: ContactUsComponent ,data:{isHead:false,homelogo:false}},
  {path:'work',component: WorkComponent ,data:{isHead:false,homelogo:false}}, 
  {path:'ai-pwa',component: AiComponent,data:{isHead:false,homelogo:false}}, 
  {path:'pwa-development',component:   PurePwaComponent,data:{isHead:false,homelogo:false}},
  {path:'new-industry',component:   NewPwaComponent,data:{isHead:false,homelogo:false}},
  {path:'native-to-pwa',component:   NativeToPwaComponent,data:{isHead:false,homelogo:false}},
  {path:'upgrade-requirements',component:   UpgradeToPwaComponent,data:{isHead:false,homelogo:false}},
  {path:'new-startups',component:  StartupsComponent,data:{isHead:false,homelogo:false}},
  {path:'new-social-network',component:  SocialNetworkComponent,data:{isHead:false,homelogo:false}},
  {path:'new-education',component: EducationComponent,data:{isHead:false,homelogo:false}},
  {path:'new-hotel',component: HospitalityComponent ,data:{isHead:false,homelogo:false}},
  {path:'design-photography',component: DonationFundingComponent,data:{isHead:false,homelogo:false}},
  {path:'new-healthcare',component: MedicalHealthcareComponent,data:{isHead:false,homelogo:false}},
  {path:'new-sports',component: SportsComponent,data:{isHead:false,homelogo:false}},
  {path:'new-art',component: ArtComponent,data:{isHead:false,homelogo:false}},
  {path:'new-business',component: BusinessComponent ,data:{isHead:false,homelogo:false}},
  {path:'new-fashion',component: FashionComponent ,data:{isHead:false,homelogo:false}},
  {path:'new-ecommerce',component: ECommerceComponent ,data:{isHead:false,homelogo:false}},
  {path:'new-food',component: FoodComponent ,data:{isHead:false,homelogo:false}},
  {path:'html',component:  HtmlComponent ,data:{isHead:false,homelogo:false}},
  {path:'word-press',component:  WordPressComponent ,data:{isHead:false,homelogo:false}},
  {path:'prestashop',component: PrestashopComponent ,data:{isHead:false,homelogo:false}},
  {path:'woo-commerce',component: WooCommerceComponent,data:{isHead:false,homelogo:false}},
  {path:'shopify',component: ShopifyComponent ,data:{isHead:false,homelogo:false}},
  {path:'joomla',component:  JoomlaComponent ,data:{isHead:false,homelogo:false}},
  {path:'php',component:  PhpComponent  ,data:{isHead:false,homelogo:false}},
  {path:'os-commerce',component:  OscommerceComponent ,data:{isHead:false}},
  {path:'opencart',component:  OpencartComponent ,data:{isHead:false,homelogo:false}},
  {path:'magento',component: MagentoComponent,data:{isHead:false,homelogo:false}},
  {path:'drupal',component:  DrupalComponent ,data:{isHead:false,homelogo:false}},
  {path:'bigcommerce',component: BigcommerceComponent ,data:{isHead:false,homelogo:false}},
  {path:'buildwizz',component:BuildwizzComponent ,data:{isHead:false,homelogo:false}},
  {path:'millennium-path-lab',component:MillenniumPathLabComponent ,data:{isHead:false,homelogo:false}},
  {path:'iot-pwa',component:IotComponent,data:{isHead:false,homelogo:false}},
  {path:'advantages',component:MiddleNewPwaComponent ,data:{isHead:false,homelogo:false}},
  {path:'goodact',component:GoodactComponent,data:{isHead:false,homelogo:false}},
  {path:'android-to-pwa',component:AndroidNativeComponent,data:{isHead:false,homelogo:false}},
  {path:'ios-to-pwa',component: IosNativeComponent,data:{isHead:false,homelogo:false}},
  {path:'hybrid-to-pwa',component: HybridAppComponent,data:{isHead:false,homelogo:false}},
  {path:'muntoo',component: MuntooComponent,data:{isHead:false,homelogo:false}},
  {path:'vellnet',component: VellnetComponent,data:{isHead:false,homelogo:false}},
  {path:'leader-first',component: LeaderFirstComponent,data:{isHead:false,homelogo:false}},
  {path:'new-pwa',component:PwaDevelopmentComponent,data:{isHead:false,homelogo:false}},
  {path:'new-time-cost',component:TimeCostComponent,data:{isHead:false,homelogo:false}},
  {path:'new-process',component:ProcessComponent,data:{isHead:false,homelogo:false}},
  {path:'new-technology',component:TechnologyComponent,data:{isHead:false,homelogo:false}},
  {path:'upgrade-success-stories',component:MinimumRequirementsComponent,data:{isHead:false,homelogo:false}},
  {path:'upgrade',component:UpgradeDevelopmentComponent ,data:{isHead:false,homelogo:false}},
  {path:'onboarding',component:OnboardingComponent ,data:{isHead:false,homelogo:false}},
  {path:'iot-smart-houses',component:SmarthousesComponent ,data:{isHead:false,homelogo:false}},
  {path:'iot-smart-retail',component:SmartRetailComponent ,data:{isHead:false,homelogo:false}},
  // {path:'smart-cities',component: SmartCitiesComponent },
  {path:'iot-healthcare',component: IotInHealthcareComponent,data:{isHead:false,homelogo:false}},
  // {path:'smart-grid',component: SmartGridComponent},
  {path:'iot-industrial',component: IndustrialIotComponent,data:{isHead:false,homelogo:false}},
  {path:'ai-video-surveillance',component: VideoSurveillanceComponent,data:{isHead:false,homelogo:false}},
  {path:'ai-ecommerce',component: EcommerceComponent,data:{isHead:false,homelogo:false}},
  {path:'ai-social-digital-marketing',component: SocialDigitalMarketingComponent ,data:{isHead:false,homelogo:false}},
  {path:'ai-chat-bots',component: ChatBotsComponent  ,data:{isHead:false,homelogo:false}},
  {path:'upgrade-free-audit',component: FreeAuditComponent ,data:{isHead:false,homelogo:false}},
  {path:'why-upgrade',component: WhyUpgradeComponent,data:{isHead:false,homelogo:false}},
  {path:'side-nav',component: SidenavComponent,data:{isHead:false,homelogo:false}},
])


export const routingComponents = [HomeComponent, ClientsComponent, ContactUsComponent, WorkComponent, AiComponent,
  PurePwaComponent, NewPwaComponent,  NativeToPwaComponent, UpgradeToPwaComponent, StartupsComponent, SocialNetworkComponent,
  EducationComponent, HospitalityComponent, SidenavComponent,DonationFundingComponent, MedicalHealthcareComponent, SportsComponent,
  ArtComponent,  BusinessComponent,  FashionComponent, ECommerceComponent, FoodComponent,  HtmlComponent,
  WordPressComponent, PrestashopComponent,MillenniumPathLabComponent, WooCommerceComponent, ShopifyComponent, JoomlaComponent,  PhpComponent,
  OscommerceComponent , OpencartComponent ,MagentoComponent,  DrupalComponent, BigcommerceComponent, BuildwizzComponent,
  IotComponent,MiddleNewPwaComponent,GoodactComponent,AndroidNativeComponent, IosNativeComponent, HybridAppComponent,
  MuntooComponent,VellnetComponent,LeaderFirstComponent,PwaDevelopmentComponent,TimeCostComponent,ProcessComponent,
  TechnologyComponent,MinimumRequirementsComponent,UpgradeDevelopmentComponent, OnboardingComponent, SmarthousesComponent,
  SmartRetailComponent, IotInHealthcareComponent, IndustrialIotComponent,
  VideoSurveillanceComponent, EcommerceComponent, SocialDigitalMarketingComponent, ChatBotsComponent,WhyUpgradeComponent,
  FreeAuditComponent,WhyUpgradeComponent
]