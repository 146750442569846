import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckdeviceosService {
  device=new BehaviorSubject('');

  constructor() { }
  
  devicesOs(os)
  {
    this.device.next(os)
  }
}