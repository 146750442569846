import { NgtUniversalModule } from '@ng-toolkit/universal';
import { HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SidebarModule} from 'primeng/sidebar';
import {MatMenuModule} from '@angular/material/menu';
 //import { HashLocationStrategy,Location,LocationStrategy, CommonModule} from '@angular/common';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FixedSideNavComponent } from './fixed-side-nav/fixed-side-nav.component';
import { FooterComponent } from './footer/footer.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import { routes, routingComponents } from './app-routing.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SwiperModule } from 'angular2-useful-swiper';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { MatInputModule} from '@angular/material/input';
import { ContactWebComponent } from './contact-web/contact-web.component';
import { WhyUpgradeComponent } from './pure-pwa/middle-new-pwa/upgrade-development/why-upgrade/why-upgrade.component';
import { MillenniumPathLabComponent } from './work/millennium-path-lab/millennium-path-lab.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SmartCitiesComponent } from './iot/smart-cities/smart-cities.component';
import { SmartGridComponent } from './iot/smart-grid/smart-grid.component';
import { SmartRetailComponent } from './iot/smart-retail/smart-retail.component';
import { SmarthousesComponent } from './iot/smarthouses/smarthouses.component';
import { AboutUsComponent } from './about-us/about-us.component';

// import {CaptchaModule} from 'primeng/captcha';






@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    HeaderComponent,

    HomeComponent,
    FooterComponent,
    FixedSideNavComponent,
    ContactWebComponent,
    WhyUpgradeComponent,
    MillenniumPathLabComponent,
    SidenavComponent,

    SmartCitiesComponent,
    SmartGridComponent,
    SmartRetailComponent,
    SmarthousesComponent,
    AboutUsComponent
  ],
  imports:[

NgtUniversalModule,
 
 TransferHttpCacheModule,
HttpClientModule,
 
    
    routes,
    FormsModule,
    BrowserModule,
    SwiperModule,
    MatSidenavModule,
    SidebarModule,
    MatMenuModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    DeviceDetectorModule.forRoot(),
    // CaptchaModule
  ],
  providers: [
   // Location,{provide:LocationStrategy,useClass:HashLocationStrategy}
  ],
})
export class AppModule { }
