import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-prestashop',
  templateUrl: './prestashop.component.html',
  styleUrls: ['./prestashop.component.css','./../html/html.component.css']
})
export class PrestashopComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  config: SwiperOptions = { 
    pagination: '.swiper-pagination',
    paginationClickable: true,
    spaceBetween: 30
    }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("PrestaShop");
    this.mobileservice.showlogo(true);
  
    
    /* ..... slide effect .... */
    setTimeout(()=>
    {
      this.htmlSlideEffect();
    },100);

  /* .... end ngoninit ..... */
  }

  htmlSlideEffect()
		{
			document.getElementById("htmltopSliding").style.left="0";
    }
  /* ..... emd slide effect .... */
}
