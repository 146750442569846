import { SwUpdate } from '@angular/service-worker';
import { WINDOW } from '@ng-toolkit/universal';
import { Component , Inject, OnInit} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CheckdeviceosService } from './checkdeviceos.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  hidefoo;
  devices: string;

  constructor(private swUpdate: SwUpdate, @Inject(WINDOW) private window: Window,  private deviceService: DeviceDetectorService,private cdos:CheckdeviceosService) {
    this.epicFunction();
   }

  ngOnInit() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe((evt) => {
                console.log('service worker updated');
            });
    
            this.swUpdate.checkForUpdate().then(() => {
                // noop
            }).catch((err) => {
                console.error('error when checking for update', err);
            });
        }}
  epicFunction() {
    const isMobile = this.deviceService.isMobile();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / this.window.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    this.devices= this.deviceService.os;
    if(isMobile && this.devices)
    {
      this.cdos.devicesOs(this.devices)
    }
  }
}
