import { Component, OnInit } from '@angular/core';
import { MobileService } from '../mobile.service';
import { Router } from '@angular/router';
import { CheckdeviceosService } from '../checkdeviceos.service';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-iot',
  templateUrl: './iot.component.html',
  styleUrls: ['./iot.component.css']
})
export class IotComponent implements OnInit {
  mobileQuery: MediaQueryList;
  _mobileQueryListener: () => void;

  constructor(private mobileservice:MobileService, private router:Router,  media: MediaMatcher) { 
    this.mobileQuery = media.matchMedia('(max-width: 766px)');
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  /*... swiper .....*/
config: SwiperOptions = { 
  pagination: '.swiper-pagination',
  paginationClickable: true,
  spaceBetween: 30
  }
/*... end swiper .....*/


  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true);
    this.mobileservice.showheading("PWA + IoT, BLE Solutions");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  }

  forMobile(link)
  {

if(this.mobileQuery.matches)
{
  this.router.navigate([link]);
}
  }
}
