import { Component, OnInit } from '@angular/core';
import { MobileService } from '../mobile.service';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true); 
    this.mobileservice.showheading("About Us");
    this.mobileservice.adclass("head-margin");
    this.mobileservice.showhidelogowhite(false);
    this.mobileservice.showhidemainlogo(true);
  }

}
