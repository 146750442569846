import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css','./../new-startups/startups.component.css']
})
export class EducationComponent implements OnInit {

    /*... swiper .....*/
    config: SwiperOptions = { 
      pagination: '.swiper-pagination',
      paginationClickable: true,
      spaceBetween: 30
      }
    /*... end swiper .....*/

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("Education");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  
/* .... slide effect ... */
    setTimeout(()=>{
      this.htmlSlideEffect();
      },100);

 /* ... end ngoninit .... */     
} 
  
    htmlSlideEffect()
    {
      document.getElementById("htmltopSliding").style.left="0";
    }
 /* .... slide effect ... */
}
