import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { MobileService } from '../../../../mobile.service';



@Component({
  selector: 'app-why-upgrade',
  templateUrl: './why-upgrade.component.html',
  styleUrls: ['./why-upgrade.component.css']
})
export class WhyUpgradeComponent implements OnInit {
  mobileQuery: MediaQueryList;
  _mobileQueryListener: () => void;
  
  constructor(private mobileservice:MobileService, private router:Router,  media: MediaMatcher) { 
    this.mobileQuery = media.matchMedia('(max-width: 766px)');
    this.mobileQuery.addListener(this._mobileQueryListener);
  }


  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true);
    this.mobileservice.showheading("Why upgrade");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  }


}
