import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-pwa-development',
  templateUrl: './pwa-development.component.html',
  styleUrls: ['./pwa-development.component.css','../middle-new-pwa.component.css']
})
export class PwaDevelopmentComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true);
    this.mobileservice.showheading("New PWA Development");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
    this.mobileservice.showhidemainlogo(true);
    this.mobileservice.showhidelogowhite(false);
  }

}
