import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-startups',
  templateUrl: './startups.component.html',
  styleUrls: ['./startups.component.css']
})
export class StartupsComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  /*... swiper .....*/
  config: SwiperOptions = { 
  pagination: '.swiper-pagination',
  paginationClickable: true,
  spaceBetween: 30
  }
/*... end swiper .....*/

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("Startups");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
    this.mobileservice.showhidemainlogo(true);
    this.mobileservice.showhidelogowhite(false);

  /* ..... slide effect .... */
    setTimeout(()=>
    {
      this.htmlSlideEffect();
    },100);

    /*.. ... end ngoninit .... */
  }

  htmlSlideEffect()
		{
			document.getElementById("htmltopSliding").style.left="0";
    }
  /* ..... end slide effect .... */
}
