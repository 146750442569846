import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../mobile.service';

@Component({
  selector: 'app-muntoo',
  templateUrl: './muntoo.component.html',
  styleUrls: ['./muntoo.component.css']
})
export class MuntooComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

      
  /*... swiper .....*/
  config: SwiperOptions = { 
    pagination: '.swiper-pagination',
    paginationClickable: true,
    spaceBetween: 30
    }
  /*... end swiper .....*/


  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false); 
    this.mobileservice.showheading("Our Work");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  }

}
