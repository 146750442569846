import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css','./../new-startups/startups.component.css']
})
export class BusinessComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  /*... swiper .....*/
config: SwiperOptions = { 
  pagination: '.swiper-pagination',
  paginationClickable: true,
  spaceBetween: 30
  }
/*... end swiper .....*/

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("Business & Services");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  
  /* ..... slide effect .... */  
  setTimeout(()=>{
      this.htmlSlideEffect();
      },100);

/* .... end ngoninit .... */
} 
  
  htmlSlideEffect()
    {
      document.getElementById("htmltopSliding").style.left="0";
    }
/* ..... slide effect .... */
}
