import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MobileService } from '../mobile.service';
import { CheckdeviceosService } from '../checkdeviceos.service';
import $ from 'jquery';
import {MediaMatcher} from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  devices: string;
  conform:boolean=true;
  detail:boolean=true;
  mobileQuery: MediaQueryList;
  _mobileQueryListener: () => void;

  //by rashmi
  myForm: FormGroup;
  file: any;

  //byharshad
  filenamelabel="Choose file"
  contactformtouched: boolean=false;
  user_location=""
  contact_form_submited=false;




  constructor(private mobileservice:MobileService,private cdos:CheckdeviceosService,changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private fb: FormBuilder)
     {
    this.mobileQuery = media.matchMedia('(max-width: 766px)');
    // console.log( this.mobileQuery.matches);
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
   }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true); 
    this.mobileservice.showheading("Contact Us");
    this.mobileservice.showlogo(false);
    
    // console.log(this.mobileQuery.matches)
    if(!this.mobileQuery.matches)
    {
      this.conform=true;
      this.detail=true;
    }
    if(this.mobileQuery.matches)
    {     
      this.conform=true;
      this.detail=false;
    }
    this.cdos.device.subscribe(c=>
    {
      this.devices=c
    })
    if(this.devices=='mac')
    {
    //  $('.form-control').addClass('ios-textbox')
    }

    
    this.myForm = this.fb.group({
      'full_name': ['', Validators.required],
      'email': ['', [Validators.required, Validators.email, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],  
      'mobile': ['', [Validators.required, Validators.pattern("^([0|+[0-9]{10})$")]],
      'interest': ["", Validators.required], 
      'requirement': ["", Validators.required],
       
      
      
    });
  }

  fileChange($event)
  {
    var file: File = $event.target.files[0];
    this.file = $event.target.files[0];
    this.filenamelabel=this.file.name;
    console.log(this.file);
   
   
  }

  myFormData(v)
{
 this.contactformtouched=true;
 this.contact_form_submited=false;
  if(this.myForm.valid)
  {
    this.contact_form_submited=true;
    const fd=new FormData()
    fd.append('full_name',this.myForm.value.full_name)
    fd.append('email',this.myForm.value.email)
    fd.append('mobile',this.myForm.value.mobile)
    fd.append('interest',this.myForm.value.interest)
    fd.append('requirement',this.myForm.value.requirement)
    fd.append('location',this.user_location)
    if(this.filenamelabel !="Choose file")
    {
      fd.append('file',this.file,this.filenamelabel)
    }
  
   console.log(this.myForm.value)
   $('#exampleModal').modal('show')
  }
}



  // userlocation(arg0: string, userlocation: any) {
  //   throw new Error("Method not implemented.");
  // }

  
  



  

showdetail(){
  if(this.mobileQuery.matches)
  {
    this.conform = false;
    this.detail = true;

  }
}

showResponse(response) {
  //call to a backend to verify against recaptcha with private key
}


}
