import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';


@Component({
  selector: 'app-upgrade-development',
  templateUrl: './upgrade-development.component.html',
  styleUrls: ['./upgrade-development.component.css','../middle-new-pwa.component.css']
})
export class UpgradeDevelopmentComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true);
    this.mobileservice.showheading("Upgrade to PWA");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
    this.mobileservice.showhidemainlogo(true);
    this.mobileservice.showhidelogowhite(false);
  }

}
