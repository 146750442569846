import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-shopify',
  templateUrl: './shopify.component.html',
  styleUrls: ['./shopify.component.css','./../html/html.component.css']
})
export class ShopifyComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  /*... swiper .....*/
  config: SwiperOptions = { 
    pagination: '.swiper-pagination',
    paginationClickable: true,
    spaceBetween: 30
    }
  /*... end swiper .....*/

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("Shopify");
    this.mobileservice.showlogo(true);
  
     
  /* ..... slide effect .... */
  setTimeout(()=>
  {
    this.htmlSlideEffect();
  },100);

  /* .... end ngoninit ..... */
}

htmlSlideEffect()
  {
    document.getElementById("htmltopSliding").style.left="0";
  }
/* ..... end slide effect .... */

}
