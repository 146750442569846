import { Component, OnInit } from '@angular/core';
import { MobileService } from '../mobile.service';
import {MediaMatcher} from '@angular/cdk/layout';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  head:boolean;
  maindiv:boolean=true;
  mobileQuery: MediaQueryList;
  _mobileQueryListener: () => void;
  constructor(private mobileservice:MobileService, media: MediaMatcher) { 
    this.mobileQuery = media.matchMedia('(max-width: 766px)');
  }

  ngOnInit() {

    this.mobileservice.showhideBackButton(false);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("Progressive Web Apps");
    this.mobileservice.showlogo(true);
    this.mobileservice.showhidemainlogo(false);
    this.mobileservice.showhidelogowhite(true);
    this.mobileservice.adclass(" ");


   this.showdetail();

    // setTimeout(()=>
    // {
    //   this.htmlSlideEffect();
    // },100);

    // setTimeout(()=>
    // {
    //   this.htmlSlideEffect2();
    // },500);

    // setTimeout(()=>
    // {
    //   this.htmlSlideEffect3();
    // },900);
  }
  


  // htmlSlideEffect()
	// 	{
	// 		document.getElementById("htmltopSliding1").style.left="0";
  //   }
  //   htmlSlideEffect2()
	// 	{
  //     document.getElementById("htmltopSliding2").style.left="0";
  //   }
  //   htmlSlideEffect3()
	// 	{
  //     document.getElementById("htmltopSliding3").style.left="0";
  //   }

    showdetail(){
      if(this.mobileQuery.matches)
      {
      this.maindiv=false;  
      }
    }
    onboarding()
    {
      this.maindiv=true;
      this.mobileservice.forHideMenu(true);
      // this.htmlSlideEffect();
      // this.htmlSlideEffect2();
      // this.htmlSlideEffect3();
    }
 
  }


