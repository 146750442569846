import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-woo-commerce',
  templateUrl: './woo-commerce.component.html',
  styleUrls: ['./woo-commerce.component.css','./../html/html.component.css']
})
export class WooCommerceComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  /*... swiper .....*/
  config: SwiperOptions = { 
    pagination: '.swiper-pagination',
    paginationClickable: true,
    spaceBetween: 30
    }
   /*... end swiper .....*/ 

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("WooCommerce");
    this.mobileservice.showlogo(true);
  
    /* ..... slide effect .... */
    setTimeout(()=>
    {
      this.htmlSlideEffect();
    },100);

 /* .... end ngoninit ... */   
  }

  htmlSlideEffect()
		{
			document.getElementById("htmltopSliding").style.left="0";
    }
  /* ..... end slide effect .... */

}
