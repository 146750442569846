import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../mobile.service';

@Component({
  selector: 'app-buildwizz',
  templateUrl: './buildwizz.component.html',
  styleUrls: ['./buildwizz.component.css']
})
export class BuildwizzComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }
/*... swiper .....*/
config: SwiperOptions = { 
  pagination: '.swiper-pagination',
  paginationClickable: true,
  spaceBetween: 30
  }
/*... end swiper .....*/
  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false); 
    this.mobileservice.showheading("Our Work");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  }

}
