import { Component, OnInit,ChangeDetectorRef} from '@angular/core';
import { MobileService } from '../mobile.service';
// import {FormControl} from '@angular/forms';
import { Location } from '@angular/common';
import {Router,ActivatedRoute,NavigationEnd} from '@angular/router' ;
import {MediaMatcher} from '@angular/cdk/layout';
import * as $ from 'jquery';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  bgcolor:boolean = false;
  homelogo:boolean=false;
  isHead ='';
  islogo='';

  // ngclass="{top-bg: $home==selectedhome}"
  // mode = new FormControl('over');

  showhide;
  topheading='';
  showmenuvalue: boolean=false;
  showmenu: boolean=false;
   adcls="";
  shwlogo;

  display;
  hidemenu=true;
  mobileQuery: MediaQueryList;
  _mobileQueryListener: () => void;

  constructor(private mobileservice:MobileService, private location:Location,private router:Router,changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,private activatedroute : ActivatedRoute) 
  { 
    
    
      this.mobileQuery = media.matchMedia('(max-width: 766px)');
      // console.log( this.mobileQuery.matches);
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    // console.log(this.router.url);
    this.router.events.pipe
    (filter(e => e instanceof NavigationEnd)).
    subscribe(()=>{
      console.log('title', this.activatedroute.root.firstChild.snapshot.data.isHead)
      this.bgcolor = this.activatedroute.root.firstChild.snapshot.data.isHead;
    })
   
    if(this.mobileQuery.matches)
    {
      
      if(this.router.url=="/home" || '/')
      {
        
        this.mobileservice.hidemenu.subscribe(data=>
          {
            this.hidemenu=data;
            // console.log('from head',data)
          })
        }
       else
      {
      
        this.mobileservice.forHideMenu(false);
      }
      this.mobileservice.hidemenu.subscribe(data=>
        {
          this.hidemenu=data;
          // console.log('from head',data)
        })
    }
  
    this.mobileservice.showback.subscribe(backarrow=>{
      this.showhide=backarrow;
    })

    this.mobileservice.showhead.subscribe(allheading=>{
      this.topheading=allheading;
      // console.log(this.topheading);
    })

    // this.mobileservice.showdetaillogo.subscribe(showtoplogo=>{
    //   this.showlogo=showtoplogo;
    //   console.log(showtoplogo)
    // })

    this.mobileservice.addclass.subscribe(classtoadd=>{
      this.adcls=classtoadd;
    })
    this.mobileservice.mainlogo.subscribe(addmainlogo1=>{
      this.shwlogo=addmainlogo1;
    })
    this.mobileservice.homelogo.subscribe(addmainlogo=>{
      this.homelogo=addmainlogo;
 
    })
  


    $(".sidebar-dropdown > a").click(function() {
      $(".sidebar-submenu").slideUp(200);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else  {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .next(".sidebar-submenu")
          .slideDown(200);
        $(this)
          .parent()
          .addClass("active");
      }

    });

   



   
    
//     $("#close-sidebar").click(function() {
//       $(".page-wrapper").removeClass("toggled");
//     });
//     $("#show-sidebar").click(function() {
//       $(".page-wrapper").addClass("toggled");
//     });
//   }
  
//   openMenu()
// {
//  this.showmenu=this.showmenu==this.showmenuvalue? true : false;
//  if(this.showmenu)
//  {
//    $('.logo-width').addClass('logo-width-open');
//    $('.con').addClass('upmenu');
//    $('.main').addClass('main1');
//    $('.hamburger').addClass('hamburger-rotate');
//    $('.left-nav').addClass('left-nav-open');
//    $('.right-nav').addClass('right-nav-open');
  
//  }
//  if(!this.showmenu)
//  {
//   $('.logo-width').removeClass('logo-width-open');
//   $('.con').removeClass('upmenu');
//   $('.hamburger').removeClass('hamburger-rotate');
//   $('.main').removeClass('main1');
//   $('.left-nav').removeClass('left-nav-open');
//   $('.right-nav').removeClass('right-nav-open');
//  }
// }




//   back(){
//     this.location.back();
//     // console.log(this.router.url)
//     if(this.router.url == "/home" )
//     {
//       this.mobileservice.forHideMenu(false)
//     }
//     if(this.router.url == "/" )
//     {
//       this.mobileservice.forHideMenu(false)
//     }
//   }

//  closeDropdown()
//  {
//    $('.sidebar-dropdown').removeClass('active');
//    $('.sidebar-submenu').css("display", "none");
//    //By rashmi
//    $('.menu-size').css("display", "block");

//  }

 
  
}

// //By rashmi
  closemenu(){
    $('.menu-size').css("display", "none");
  }
  Showmenu(){
    $('.menu-size').css("display", "block");
  }

  changeWidth()
  {
    console.log("aaaa");
    $('.ui-sidebar').addClass('changeWidth');
    $('#anchor_bg').addClass('anchor_bg');
  }

  // normalWidth()
  // {
  //   console.log("aaaa");
  //   $('.ui-sidebar').addClass('normalWidth');
  // } 

}