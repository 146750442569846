import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-php',
  templateUrl: './php.component.html',
  styleUrls: ['./php.component.css','./../html/html.component.css']
})
export class PhpComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

    /*... swiper .....*/
    config: SwiperOptions = { 
      pagination: '.swiper-pagination',
      paginationClickable: true,
      spaceBetween: 30
      }
    /*... end swiper .....*/

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("PHP");
    this.mobileservice.showlogo(true);
  
  /* ..... slide effect .... */
  setTimeout(()=>
  {
    this.htmlSlideEffect();
  },100);

/* ... end ngoninit .... */  
}

htmlSlideEffect()
  {
    document.getElementById("htmltopSliding").style.left="0";
  }
/* ..... end slide effect .... */

}
