import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-hybrid-app',
  templateUrl: './hybrid-app.component.html',
  styleUrls: ['./hybrid-app.component.css']
})
export class HybridAppComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true);
    this.mobileservice.showheading("Hybrid App");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  }

}
