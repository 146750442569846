import { Component, OnInit } from '@angular/core';
import { MobileService } from '../mobile.service';


@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true); 
    this.mobileservice.showheading("Clients");
    this.mobileservice.showlogo(false);
    this.mobileservice.showhidemainlogo(true);
    this.mobileservice.showhidelogowhite(false);
    this.mobileservice.adclass("head-margin");
  }

}
