import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../mobile.service';

@Component({
  selector: 'app-middle-new-pwa',
  templateUrl: './middle-new-pwa.component.html',
  styleUrls: ['./middle-new-pwa.component.css']
})
export class MiddleNewPwaComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  /*... swiper .....*/
  config: SwiperOptions = { 
    pagination: '.swiper-pagination',
    paginationClickable: true,
    spaceBetween: 30
    }
/*... end swiper .....*/

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true);
    this.mobileservice.showheading("Advantages");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  }

}
