import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-minimum-requirements',
  templateUrl: './minimum-requirements.component.html',
  styleUrls: ['./minimum-requirements.component.css','../middle-new-pwa.component.css']
})
export class MinimumRequirementsComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

   /*... swiper .....*/
  //  config: SwiperOptions = { 
  //   pagination: '.swiper-pagination',
  //   paginationClickable: true,
  //   spaceBetween: 0,
  //   slidesPerView:1.1
  //   }
  /*... end swiper .....*/

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true);
    this.mobileservice.showheading("Success Stories");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  }

}
