import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-word-press',
  templateUrl: './word-press.component.html',
  styleUrls: ['./word-press.component.css','./../html/html.component.css']
})
export class WordPressComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  config: SwiperOptions = { 
    pagination: '.swiper-pagination',
    paginationClickable: true,
    spaceBetween: 30
    }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("WordPress");
    this.mobileservice.showlogo(true);
    this.mobileservice.adclass("head-margin");
  
    setTimeout(()=>
    {
      this.htmlSlideEffect();
    },100);
  }

  htmlSlideEffect()
		{
			document.getElementById("htmltopSliding").style.left="0";
    }

}
