import { Component, OnInit } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-fixed-side-nav',
  templateUrl: './fixed-side-nav.component.html',
  styleUrls: ['./fixed-side-nav.component.css']
})
export class FixedSideNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit()
  {
    $("#fixed-form-container .body").hide();
    $("#fixed-form-container .button").click(function () {           
       //$(this).next("#fixed-form-container div").animate({width: 'toggle'});            
       $("#fixed-form-container .body").animate({right:0, opacity:"show",width:'toggle'}, 1000);
       $(this).toggleClass("expanded");
        });

        //code for side nav active element
      /* $(document).ready(function(){
         if( $('#fixed-form-container ul li a').hasClass('active')){
          $('#fixed-form-container ul li').addClass("border-l");
         }
         else {
         $('#fixed-form-container ul li').removeClass("border-l");
         };
        */ 

      
      
       
  }

}
