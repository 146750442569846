import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';

@Component({
  selector: 'app-oscommerce',
  templateUrl: './oscommerce.component.html',
  styleUrls: ['./oscommerce.component.css','./../html/html.component.css']
})
export class OscommerceComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("OsCommerce");
    this.mobileservice.showlogo(true);
  }

}
