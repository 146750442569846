import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../../mobile.service';


@Component({
  selector: 'app-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.css']
})
export class HtmlComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

 
/*... swiper .....*/
  config: SwiperOptions = { 
    pagination: '.swiper-pagination',
    paginationClickable: true,
    spaceBetween: 30
    }
/*... end swiper .....*/

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("HTML");
    this.mobileservice.showlogo(true);
    this.mobileservice.adclass("head-margin");
    
 
  /* ..... slide effect .... */
    setTimeout(()=>
    {
      this.htmlSlideEffect();
    },100);
  }

  htmlSlideEffect()
		{
			document.getElementById("htmltopSliding").style.left="0";
    }
  /* ..... end slide effect .... */

}
