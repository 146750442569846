import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../mobile.service';

@Component({
  selector: 'app-industrial-iot',
  templateUrl: './industrial-iot.component.html',
  styleUrls: ['./industrial-iot.component.css', '../smarthouses/smarthouses.component.css']
})
export class IndustrialIotComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  /*... swiper .....*/
  config: SwiperOptions = { 
    pagination: '.swiper-pagination',
    paginationClickable: true,
    spaceBetween: 30
    }
  /*... end swiper .....*/
  

  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("Industrial IoT");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  }

}
