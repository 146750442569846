import { Component, OnInit } from '@angular/core';
import { MobileService } from '../mobile.service';
import { Router } from '@angular/router';
import { CheckdeviceosService } from '../checkdeviceos.service';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-ai',
  templateUrl: './ai.component.html',
  styleUrls: ['./ai.component.css']
})
export class AiComponent implements OnInit {
  mobileQuery: MediaQueryList;
  _mobileQueryListener: () => void;

  constructor(private mobileservice:MobileService,  private router:Router,  media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 766px)');
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  
  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(true);
    this.mobileservice.showheading("AI with PWA");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
    this.mobileservice.showhidelogowhite(false);
    this.mobileservice.showhidemainlogo(true);
  }
  forMobile(link)
  {

if(this.mobileQuery.matches)
{
  this.router.navigate([link]);
}
  }
}
