import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../mobile.service';

@Component({
  selector: 'app-smart-retail',
  templateUrl: './smart-retail.component.html',
  styleUrls: ['./smart-retail.component.css','../smarthouses/smarthouses.component.css']
})
export class SmartRetailComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }
  
  /*... swiper .....*/
config: SwiperOptions = { 
  pagination: '.swiper-pagination',
  paginationClickable: true,
  spaceBetween: 30
  }
/*... end swiper .....*/


  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("Smart Retails");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  }

}
