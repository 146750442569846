import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs-compat';

@Injectable({
  providedIn: 'root'
})
export class MobileService {
  homeelogo(arg0: boolean) {
    throw new Error("Method not implemented.");
  }

  constructor() { }

  /* ...... back button...*/
showback=new BehaviorSubject(false);
showhideBackButton(showhideback:boolean)
{
this.showback.next(showhideback);
}

/* ...... footer ..... */
showfooter=new BehaviorSubject(true);
showhidefooter(showfoo:boolean){
  this.showfooter.next(showfoo);
}

/* ..... heading .... */
showhead= new BehaviorSubject("Progressive Web Apps");
showheading(showhd:string){
  this.showhead.next(showhd);
}

/* ..... header logo.... */
showdetaillogo= new BehaviorSubject(false);
showlogo(showdlogo:boolean){
  this.showdetaillogo.next(showdlogo);
}

/* .... add class .... */
addclass= new BehaviorSubject(" ");
adclass(marclass:string){
  this.addclass.next(marclass);
}

/**menu for mobile on home page**/
hidemenu=new BehaviorSubject(false)
forHideMenu(v)
{
  this.hidemenu.next(v)
}


/* ...... main logo  ..... */
mainlogo=new BehaviorSubject(true);
showhidemainlogo(showlogo){
  this.mainlogo.next(showlogo);
}

homelogo=new BehaviorSubject(true);
showhidelogowhite(homeelogo){
  this.homelogo.next(homeelogo);
  
}

}
