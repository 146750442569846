import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../mobile.service';

@Component({
  selector: 'app-iot-in-healthcare',
  templateUrl: './iot-in-healthcare.component.html',
  styleUrls: ['./iot-in-healthcare.component.css', '../smarthouses/smarthouses.component.css']
})
export class IotInHealthcareComponent implements OnInit {

  constructor(private mobileservice:MobileService) { }

  
  /*... swiper .....*/
config: SwiperOptions = { 
  pagination: '.swiper-pagination',
  paginationClickable: true,
  spaceBetween: 30
  }
/*... end swiper .....*/


  ngOnInit() {
    this.mobileservice.showhideBackButton(true);
    this.mobileservice.showhidefooter(false);
    this.mobileservice.showheading("IoT in Healthcare");
    this.mobileservice.showlogo(false);
    this.mobileservice.adclass("head-margin");
  }

}
